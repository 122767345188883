.contact {
    padding: 0rem 0 5rem 0;
    background-color: #000;
    padding-bottom: -200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    height: 100vh;
    color: #000d1a;
    font-size: 1.2 rem;
    position: relative;
    z-index: 999;
    background-color: #f9f9f9;
}

.contact-header {
    padding: 20px;
    padding-bottom: 10px;
    color: #000d1a;
    border-bottom: 1px solid ;
    font-size: 2.2rem;
    text-align: center;
}

.contact-sub-header {
    font-size: 1.2rem;
    padding: 20px;
    text-align: center;
    
}

.contact-container {
    border-radius: 0.5rem;
    /*border: 1px solid;*/
    padding: 0.5rem;
}

.contact-input-items {
    padding: 5px;
}

.input-box {
    width: 80%;
    height: 100%;
    border: 2px solid #000d1a;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1rem;
    border-radius: 0.5rem;
    outline: none;
    padding: 0.5rem;
    color: black;
    background: none;
}
.input-box:hover {
   /*border-color: #8dffff;*/
   border-color: rgb(248, 208, 32);;
    
}
.input-box:focus {
   /* border-color: #18ffff;*/
    border-color: orangered;
}

.contact-2 {
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-2-header {
    padding: 20px;
    padding-bottom: 10px;
    color: rgb(9, 6, 98);
    border-bottom: 1px solid rgb(9, 6, 98);
    font-size: 1.8rem;
}

.contact-2-sub-header {
    font-size: 1.2rem;
    padding: 20px;
    color: rgb(9, 6, 98);
}

.contact-btn {
    
    margin-left: 0%;
    width: 0px;
    align-self: center;
}

@media screen and (max-width:800px) {
    .input-box {
        width: 90%;
        height: 40px;
    }
    .contact-btn {
        margin-left: 0%;
        
    }
    .contact-header {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 1200px) {
    .input-box {
        width: 500px;;
        height: 50px;
    }
    .contact-btn {
        margin-left: 27%;
        margin-top: 20px;
    }
}