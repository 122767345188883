.wrapper {
    max-width: 1350px;
    padding: 0px 60px;
    margin: 50px auto;
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
    
}

.gallery-poet {
    margin-bottom: 10px;
}

.gallery-text {
    margin-top: 10%;
    font-family: 'Kelvinch';
}

.wrapper img {
    cursor: pointer;
    width: 500px;
    height: 100%;
    object-fit: cover;
}
.wrapper img:hover {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    margin-top: 64px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    
}

.overlay img {
    display: flex;
   height: 600px;
   object-fit: cover;
    max-width: 70%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.gallery-p {
    margin-top: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width:768px) {
    .wrapper img {
        width: 100%;
        height: 100%;
        padding-bottom: -700px;
    }
    .wrapper {
        grid-template-columns: 1fr;
        grid-gap: 0em;
    }
    .overlay img {
        display: flex;
       height: 300px;
       object-fit: cover;
        max-width: 70%;
        margin: 60px auto;
        margin-top: 40%;
        box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    }
   
}
@media screen and (max-width: 500px) {
    .overlay img {
        margin-top: 60%;
    }
}
@media screen and (min-width: 1000px) {
    .wrapper img {
        width: 800px;
    }
    .gallery-text {
        margin-top: 40%;
        font-size: 2rem;
    }
    .gallery-p {
        margin-top: 20px;
        font-size: 1.2rem;
    }
    .gallery-poet {
        margin-top: 20%;
        font-size: 2rem;
    }
    .gallery-poem {
        font-size: 1.2rem;
    }
}

.overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    text-decoration: none;
    z-index: 999;
    cursor: pointer;
}

.fa-solid {
    font-size: 3rem;
}

.overlay-arrows__left i {
    width: 50px;
    height: 50px;
}

.overlay-arrows__right i {
    width: 50px;
    height: 50px;
}

.overlay-arrows__right {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
}

.overlay-arrows__left {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
}

.xmark {
    color: #ffffff;
}