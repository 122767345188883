.card {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 350px;
    height: 525px;
    object-fit: cover;
    opacity: 0;
    transition: 500ms ease;
}

.card--active {
    opacity: 1;
    transform: scale(1);
    z-index: 99;
    
}
.card--active:hover {
    transform: scale(1.08);
    cursor: pointer;
}
.card--left {
    transform: translateX(-125%) scale(0.8);
    transition: 500ms;
    opacity: 0.3;
}
.card--right {
    transform: translateX(125%) scale(0.8);
    transition: 500ms;
    opacity: 0.3;
}
.carousel {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: -100px;
}
.cursor {
    background: linear-gradient(30deg, rgb(248, 208, 32), yellow);
  height: 250px;
  top: 0;
  left: 0;
  width: 250px;
  border-radius: 50%;
  filter: blur(200px);
}

@media screen and (max-width: 600px) {
    .carousel {
        width: 100%;
        height: 100%;
        position: relative;
        /*margin-top: -125px;*/
    }
    .cursor {
        display: none;
    }
}